<template>
  <!-- <v-content> -->
  <v-main>
    <router-view />

    <!-- <dashboard-core-footer /> -->
    <!-- </v-content> -->
  </v-main>
</template>

<script>
export default {
  name: "DashboardCoreView",

  components: {
    DashboardCoreFooter: () => import("./Footer")
  }
};
</script>
